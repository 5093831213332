export const NAV_BANNER_AD_SIZES: ResponsiveAdSize[] = [
  {
    adDimensions: [
      [728, 90],
      [970, 250],
    ],
    screenDimensions: [2000, 1600],
  },
  {
    adDimensions: [[728, 90]],
    screenDimensions: [900, 600],
  },
  {
    adDimensions: [
      [320, 50],
      [300, 50],
    ],
    screenDimensions: [600, 400],
  },
  {
    adDimensions: [[300, 50]],
    screenDimensions: [300, 300],
  },
  {
    adDimensions: [],
    screenDimensions: [0, 0],
  },
];
