const getLocationType = (campgroundOrLocation: CampgroundOrLocationModel) => {
  if (campgroundOrLocation.type === 'location-search-results') {
    if (campgroundOrLocation.locationType === 'dump_station') {
      return 'dump_station';
    } else if (campgroundOrLocation.locationType === 'water_station') {
      return 'water_station';
    } else if (campgroundOrLocation.pinType === 'overnight_parking') {
      return 'overnight_parking';
    } else return null;
  } else return null;
};

export default getLocationType;
