import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import classNames from 'classnames/bind';
import kebabCase from 'lodash.kebabcase';

import { NEARBY_CAMPGROUND_CLICK } from 'constants/amplitude';
import { SEO_TOP10_AMENITIES } from 'constants/campground';

import { useUser } from 'contexts/currentUser';

import getCampgroundNumberSaves from 'utils/getCampgroundNumberSaves';
import getPrices from 'utils/getCampgroundPrices';
import { quantitativeDescription } from 'utils/quantitativeDescription';

import AppCampgroundPillDiscount from 'components/AppCampgroundPills/AppCampgroundPillDiscount';
import AppCampgroundPillInstant from 'components/AppCampgroundPills/AppCampgroundPillInstant';
import AppIcon from 'components/AppIcon/AppIcon';
import AppLink from 'components/AppLink/AppLink';
import AppListButton from 'components/AppListButton/AppListButton';
import AppMarkdown from 'components/AppMarkdown/AppMarkdown';
import AppPhoto from 'components/AppPhoto/AppPhoto';
import AppStarRating from 'components/AppStarRating/AppStarRating';

import styles from './styles/AppCardCarousel.module.scss';

const cx = classNames.bind(styles);

export interface CampgroundFullInfoCardProps {
  campground: CampgroundModel;
  nearestCity?: PlaceModel;
  hasBackground?: boolean;
  className?: string;
  position?: number;
}

export const _CampgroundFullInfoCard = React.forwardRef<
  HTMLDivElement,
  CampgroundFullInfoCardProps
>(
  (
    {
      campground,
      nearestCity,
      className,
      hasBackground,
      position,
    }: CampgroundFullInfoCardProps,
    ref
  ) => {
    const currentUserContext = useUser();

    const currentUser = currentUserContext?.user;

    const { slug: nearestCitySlug } = nearestCity || {};

    const cardClassName = cx({
      cardFullInfo: true,
      'cardFullInfo--has-background': hasBackground,
    });

    const imageClassName = cx({
      cardFullInfo__image: true,
      'cardFullInfo__image--has-background': hasBackground,
    });

    const aboutClassName = cx({
      cardFullInfo__about: true,
      'cardFullInfo__about--has-background': hasBackground,
    });

    let altText;

    switch (campground.bookingMethod) {
      case 'instant':
      case 'request':
        altText = `Campground Finder: ${campground.name}`;
        break;
      case 'ridb':
        altText = `Public Campgrounds: ${campground.name}`;
        break;
      default:
        altText = `${campground.name}`;
        break;
    }

    const price = getPrices(campground);

    const validAmenities = SEO_TOP10_AMENITIES.filter((someType) => {
      const thisCampground = campground as CampgroundModel;

      return !!thisCampground[someType.value];
    });

    return (
      <div
        className={`${cardClassName} ${className}`}
        ref={ref}
        onClick={() =>
          amplitude.track(NEARBY_CAMPGROUND_CLICK, {
            'display order': position,
            'campground name': campground.name,
            'campground region': campground.regionName,
          })
        }
      >
        {currentUser && (
          <AppListButton
            className={styles['cardFullInfo__list-button']}
            campground={campground}
          />
        )}
        <AppLink
          href={`/camping/${kebabCase(campground.regionName)}/${
            campground.slug
          }`}
        >
          <a
            className={`${styles['cardFullInfo__link']}`}
            data-event="Consumer_Carousel-Campground-Card"
          >
            <div className={styles['cardFullInfo__image-container']}>
              <AppPhoto
                className={imageClassName}
                src={campground.photoUrl}
                alt={altText}
                options={{
                  width: 260,
                  height: 260,
                  fit: 'crop',
                }}
                width="260"
                height="260"
              />
              <AppCampgroundPillDiscount
                campground={campground}
                className={styles['card__pro-upsell']}
              />
              <AppCampgroundPillInstant
                campground={campground}
                className={styles['card__instant-book']}
              />
            </div>
          </a>
        </AppLink>

        <div className={aboutClassName}>
          <AppLink
            href={`/camping/${kebabCase(campground.regionName)}/${
              campground.slug
            }`}
          >
            <a
              className={`${styles['cardFullInfo__link']}`}
              data-event="Consumer_Carousel-Campground-Card"
            >
              <div>
                <h3 className={styles['cardFullInfo__title']}>
                  {campground.name}
                </h3>
              </div>
              {campground.reviewsCount > 0 && (
                <section>
                  <AppStarRating rating={campground.rating || 0} size="md" />
                  <span className={styles['cardFullInfo__reviews']}>
                    {quantitativeDescription('Review', campground.reviewsCount)}
                  </span>
                </section>
              )}
              <div className={styles['cardFullInfo__photoSaveContainer']}>
                {!!campground.photosCount && (
                  <section>
                    <span className={styles['cardFullInfo__photos']}>
                      {quantitativeDescription('Photo', campground.photosCount)}
                    </span>
                  </section>
                )}
                {getCampgroundNumberSaves(campground) && (
                  <>
                    <span className={styles['cardFullInfo__textDot']}></span>
                    <div className={styles['cardFullInfo__saves']}>
                      {quantitativeDescription(
                        'Save',
                        getCampgroundNumberSaves(campground)?.listsCount || 0
                      )}
                    </div>
                  </>
                )}
              </div>
            </a>
          </AppLink>

          {!!campground.nearestCityName &&
            !!campground.nearestCityRegionName &&
            !!nearestCitySlug && (
              <div className={styles['cardFullInfo__linkContainer']}>
                <AppLink
                  href={`/camping/${kebabCase(
                    campground.nearestCityRegionName
                  )}/near/${nearestCitySlug}`}
                >
                  <a className={styles['cardFullInfo__link']}>
                    {campground.nearestCityName},{' '}
                    {campground.nearestCityRegionName}
                  </a>
                </AppLink>
              </div>
            )}
          <section className={styles['cardFullInfo__markdownContainer']}>
            <AppMarkdown
              content={campground?.description}
              truncate={2}
              expandable={false}
            />
          </section>
          <section>
            <ul className={styles['cardFullInfo__ammenities']}>
              {!!validAmenities &&
                validAmenities.slice(0, 4).map((type) => (
                  <li
                    key={type.label}
                    className={styles['cardFullInfo__ammenities__item']}
                  >
                    <AppIcon
                      classNameWrapper={
                        styles['cardFullInfo__ammenities__icon-container']
                      }
                      category={type.iconCategory}
                      icon={type.icon}
                    />
                    <span className={styles['cardFullInfo__ammenities__label']}>
                      {type.label}
                    </span>
                  </li>
                ))}
            </ul>
          </section>

          {!!price && (
            <section>
              <p className={styles['cardFullInfo__price']}>
                <strong>{price}</strong> / night
              </p>
            </section>
          )}
        </div>
      </div>
    );
  }
);

_CampgroundFullInfoCard.displayName = '_CampgroundFullInfoCard';

export default _CampgroundFullInfoCard;
