import React from 'react';
import classNames from 'classnames/bind';
import { format } from 'date-fns';

import AppLink from 'components/AppLink/AppLink';
import AppPhoto from 'components/AppPhoto/AppPhoto';

import styles from './styles/AppCardCarousel.module.scss';

const cx = classNames.bind(styles);

export interface ArticleCardProps {
  article: {
    id: number;
    title: string;
    author: string;
    date: string;
    link: string;
    picture: string;
    description: string;
    categories: string[];
  };
  className?: string;
}

export const ArticleCard = React.forwardRef<
  HTMLAnchorElement,
  ArticleCardProps
>(({ article, className }: ArticleCardProps, ref) => {
  const formattedDate = format(new Date(article.date), 'MMMM d, yyyy');

  const categoriesClassNames = article.categories.map((category) =>
    cx({
      card__categories: true,
      'card__categories--lifestyle': category === 'Lifestyle',
      'card__categories--featured': category === 'Featured',
      'card__categories--gear': category === 'Gear',
      'card__categories--local': category === 'Local',
      'card__categories--sponsored': category === 'Sponsored',
      'card__categories--hosting': category === 'Hosting',
    })
  );

  return (
    <AppLink href={article.link}>
      <a
        className={`${styles['card']} ${styles['card--is-article']} ${className}`}
        ref={ref}
        data-event={'Consumer_Carousel-Article-Card'}
      >
        <AppPhoto
          className={styles['card__image']}
          src={article.picture}
          alt={article.title}
          options={{
            width: 340,
            height: 199,
            fit: 'crop',
            crop: 'smart',
          }}
        />
        <div className={styles['card__categories-container']}>
          {categoriesClassNames.map((categoryClass, index) => (
            <span key={index} className={categoryClass}>
              {article.categories[index]}
            </span>
          ))}
        </div>
        <div className={styles['card__content']}>
          <h3 className={styles['card__title']}>{article.title}</h3>
          <span className={styles['card__author-container']}>
            <span className={styles['card__prefix']}>by </span>{' '}
            <span className={styles['card__author']}> {article.author} </span>
            <span className={styles['card__prefix']}> on </span>
            <span className={styles['card__author']}>{formattedDate}</span>
          </span>
          <span className={styles['card__description']}>
            {article.description}
          </span>
        </div>
      </a>
    </AppLink>
  );
});

ArticleCard.displayName = 'ArticleCard';

export default ArticleCard;
