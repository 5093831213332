import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import classNames from 'classnames/bind';
import kebabCase from 'lodash.kebabcase';

import { NEARBY_CAMPGROUND_CLICK } from 'constants/amplitude';

import { useUser } from 'contexts/currentUser';

import AppCampgroundPillDiscount from 'components/AppCampgroundPills/AppCampgroundPillDiscount';
import AppCampgroundPillInstant from 'components/AppCampgroundPills/AppCampgroundPillInstant';
import AppLink from 'components/AppLink/AppLink';
import AppListButton from 'components/AppListButton/AppListButton';
import AppPhoto from 'components/AppPhoto/AppPhoto';
import AppStarRating from 'components/AppStarRating/AppStarRating';

import styles from './styles/AppCardCarousel.module.scss';

const cx = classNames.bind(styles);

export interface CampgroundCardProps {
  campground: CampgroundOrLocationModel;
  hasBackground?: boolean;
  className?: string;
  position?: number;
}

export const _CampgroundCard = React.forwardRef<
  HTMLDivElement,
  CampgroundCardProps
>(
  (
    { campground, className, hasBackground, position }: CampgroundCardProps,
    ref
  ) => {
    const currentUserContext = useUser();

    const currentUser = currentUserContext?.user;

    const cardClassName = cx({
      card: true,
      'card--has-background': hasBackground,
    });

    const imageClassName = cx({
      card__image: true,
      'card__image--has-background': hasBackground,
    });

    const aboutClassName = cx({
      card__about: true,
      'card__about--has-background': hasBackground,
    });

    let altText;

    switch (campground.bookingMethod) {
      case 'instant':
      case 'request':
        altText = `Campground Finder: ${campground.name}`;
        break;
      case 'ridb':
        altText = `Public Campgrounds: ${campground.name}`;
        break;
      default:
        altText = `${campground.name}`;
        break;
    }

    return (
      <div
        className={`${cardClassName} ${className}`}
        ref={ref}
        onClick={() =>
          amplitude.track(NEARBY_CAMPGROUND_CLICK, {
            'display order': position,
            'campground name': campground.name,
            'campground region': campground.regionName,
          })
        }
      >
        {currentUser && (
          <AppListButton
            className={styles['card__list-button']}
            campground={campground}
          />
        )}
        <AppLink
          href={`/camping/${kebabCase(campground.regionName)}/${
            campground.slug
          }`}
        >
          <a
            className={`${styles['card__link']}`}
            data-event="Consumer_Carousel-Campground-Card"
          >
            <div className={styles['card__image-container']}>
              <AppPhoto
                className={imageClassName}
                src={campground.photoUrl}
                alt={altText}
                options={{
                  width: 285,
                  height: 200,
                  fit: 'crop',
                  crop: '7:5,smart',
                }}
                width="285"
                height="200"
              />
              <AppCampgroundPillDiscount
                campground={campground}
                className={styles['card__pro-upsell']}
              />
              <AppCampgroundPillInstant
                campground={campground}
                className={styles['card__instant-book']}
              />
            </div>
            <div className={aboutClassName}>
              <h3 className={styles['card__title']}>{campground.name}</h3>
              <div className={styles['card__rating']}>
                {!campground.reviewsCount && <>Be the first to review!</>}
                {campground.reviewsCount > 0 && (
                  <>
                    <AppStarRating rating={campground.rating || 0} size="sm" />
                    <span className={styles['card__rating-label']}>
                      {campground.reviewsCount} Reviews
                    </span>
                  </>
                )}
              </div>
            </div>
          </a>
        </AppLink>
      </div>
    );
  }
);

_CampgroundCard.displayName = '_CampgroundCard';

export default _CampgroundCard;
