export const pluralize = (
  number: number | null,
  plural: string,
  singular: string,
  none: string = plural
) => {
  if (!plural || !singular) {
    return '';
  }

  return number === 0 ? none || plural : number === 1 ? singular : plural;
};
