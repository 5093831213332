import React from 'react';
import startCase from 'lodash.startcase';

import styles from './styles/AppCampgroundPill.module.scss';

interface Props {
  campground: CampgroundOrLocationModel;
  className?: string;
}

const AppCampgroundPillAvailability: React.FC<Props> = ({
  campground,
  className,
}) => {
  return campground.availability ? (
    <span
      className={`${className} ${styles['availability']} ${styles['pill']}`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <span
        className={`${styles[`availability__indicator`]} ${
          styles[`availability__indicator--${campground.availability}`]
        }`}
      />

      <span>&nbsp;{startCase(campground.availability)}</span>
    </span>
  ) : null;
};

export default AppCampgroundPillAvailability;
