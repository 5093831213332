const getPrices = (campground: CampgroundOrLocationModel) => {
  const low =
    campground.priceLow && campground.priceLow !== '0.00'
      ? Number(campground.priceLow).toFixed(0)
      : null;
  const high =
    campground.priceHigh && campground.priceHigh !== '0.00'
      ? Number(campground.priceHigh).toFixed(0)
      : null;

  if (low && !high) {
    return `$${low}`;
  }

  if (!low && high) {
    return `$${high}`;
  }

  if (low && high) {
    return low === high ? `$${low}` : `$${low} - $${high}`;
  }

  return null;
};

export default getPrices;
