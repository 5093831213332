import React, { useEffect, useRef } from 'react';
import { Router } from 'next/router';
import classNames from 'classnames/bind';

import { useUser } from 'contexts/currentUser';
import { useGoogleAdContext } from 'contexts/googleAds';

import { displayAds, removeSlot } from 'utils/googleAds';
import isMobileDevice from 'utils/isMobileDevice';
import { isCTAHidden } from 'utils/localStorage';

import { useOverlayContext } from 'hooks/useOverlayContext';

import styles from './styles/GoogleAds.module.scss';

const cx = classNames.bind(styles);

interface Props {
  sizes: ResponsiveAdSize[];
  divId: string;
  adType: DyrtAdType;
  className?: string;
  hide?: boolean;
  centered?: boolean;
  showToPro?: boolean;
}

export const ResponsiveNonProGoogleAd: React.FC<Props> = ({
  sizes,
  divId,
  adType,
  className,
  hide = false,
  centered,
  showToPro = false,
}) => {
  const { user, loading } = useUser();
  const slot = useRef<googletag.Slot | null>(null);
  const { overlays } = useOverlayContext();
  const { ready } = useGoogleAdContext();
  const showAd = (showToPro || (!loading && !user?.pro)) && !hide;

  useEffect(() => {
    if (!ready) return;
    try {
      const awaitingSeeInApp =
        !isCTAHidden('hide-see-in-app-until') && isMobileDevice();

      if (!showAd && slot.current) {
        removeSlot(slot.current);
        slot.current = null;
      } else if (
        showAd &&
        !slot.current &&
        !awaitingSeeInApp &&
        !overlays?.length
      ) {
        const newSlot = displayAds(adType, sizes, divId);
        slot.current = newSlot;
      }
    } catch (e) {
      console.error('Error displaying ad ' + divId, e);
    }

    const handleRouteChangeStart = () => {
      if (slot.current) {
        removeSlot(slot.current);
        slot.current = null;
      }
    };
    Router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      Router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [adType, divId, sizes, showAd, slot, overlays?.length, ready]);

  const classes = cx({
    ad: true,
    'ad--centered': centered,
  });

  return <div id={divId} className={`${className} ${classes}`}></div>;
};

export default ResponsiveNonProGoogleAd;
