import React from 'react';

import AppIcon from 'components/AppIcon/AppIcon';

import styles from './styles/AppCampgroundPill.module.scss';

interface Props {
  campground: CampgroundOrLocationModel;
  className?: string;
  iconClassName?: string;
}

const AppCampgroundPillInstant: React.FC<Props> = ({
  campground,
  className,
  iconClassName,
}) => {
  return campground.bookingMethod === 'instant' ||
    campground.bookingMethod === 'request' ? (
    <span
      className={`${styles['instant-book']} ${styles['pill']} ${className}`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      &nbsp;
      {campground.bookingMethod === 'instant'
        ? 'Instant Book'
        : 'Request to Book'}
      {campground.bookingMethod === 'instant' && (
        <AppIcon
          icon="instant_book"
          classNameWrapper={`${styles['instant-book__icon']} ${iconClassName}`}
        />
      )}
    </span>
  ) : null;
};

export default AppCampgroundPillInstant;
