import React from 'react';

import AppIcon from 'components/AppIcon/AppIcon';

import styles from './styles/AppCampgroundPill.module.scss';

interface Props {
  campground: CampgroundOrLocationModel;
  className?: string;
}

const AppCampgroundPillDiscount: React.FC<Props> = ({
  campground,
  className,
}) => {
  return campground.partner ? (
    <span
      className={`${className} ${styles['pro-upsell']} ${styles['pill']}`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <AppIcon
        classNameSvg={[styles['icon-small']]}
        icon="pro_white_punched_out"
      />
      &nbsp;
      {campground.partner === 'standard' ? '10%' : 'Up to 40%'} off
    </span>
  ) : null;
};

export default AppCampgroundPillDiscount;
