import React from 'react';
import { ClientSafeProvider } from 'next-auth/react';
import { useRouter } from 'next/router';
import classNames from 'classnames/bind';

import { campgroundOrLocationUrl } from 'utils/campgroundOrLocationUrl';
import getLocationType from 'utils/getLocationType';

import AppCampgroundPillAvailability from 'components/AppCampgroundPills/AppCampgroundPillAvailability';
import AppCampgroundPillDiscount from 'components/AppCampgroundPills/AppCampgroundPillDiscount';
import AppCampgroundPillInstant from 'components/AppCampgroundPills/AppCampgroundPillInstant';
import AppLink from 'components/AppLink/AppLink';
import AppListButton from 'components/AppListButton/AppListButton';
import AppPhoto from 'components/AppPhoto/AppPhoto';
import AppStarRating from 'components/AppStarRating/AppStarRating';

import styles from './styles/AppCampgroundCard.module.scss';

const cx = classNames.bind(styles);
export interface AppCampgroundCardProps {
  campgroundOrLocation: CampgroundOrLocationModel;
  className?: string;
  classNameImgContainer?: string;
  newTab?: boolean;
  adminArea?: AdministrativeAreaModel;
  providers?: Record<string, ClientSafeProvider> | null;
  imageCardOnly?: boolean;
  imageTall?: boolean;
  lazyLoad?: boolean;
}

export const AppCampgroundCard = React.forwardRef<
  HTMLDivElement,
  AppCampgroundCardProps
>(
  (
    {
      campgroundOrLocation,
      className,
      classNameImgContainer,
      newTab,
      adminArea,
      imageCardOnly,
      imageTall,
      lazyLoad = true,
    }: AppCampgroundCardProps,
    ref
  ) => {
    const router = useRouter();

    const locationType = getLocationType(campgroundOrLocation);

    const { query: { filters } = {} } = router;

    let startDate = '';
    let endDate = '';
    try {
      if (filters) {
        const searchFilters = JSON.parse(filters as string);
        startDate = searchFilters?.start_date;
        endDate = searchFilters?.end_date;
      }
    } catch (e) {
      console.error(e);
    }

    const isDumpOrWater =
      locationType === 'water_station' || locationType === 'dump_station';

    const imgClasses = cx({
      card__image: true,
      'card__image--tall': imageTall,
      card__imagePin: !!locationType,
    });

    const imgContainerClasses = cx({
      'card__image-container': true,
      'card__image-container--tall': imageTall,
    });

    const WIDTH = 327;
    const HEIGHT = imageTall ? 300 : 200;

    return (
      <div
        className={`${styles['card']} ${styles['card--is-campground']} ${className}`}
        ref={ref}
      >
        <AppLink
          href={`${campgroundOrLocationUrl(campgroundOrLocation)}${
            startDate && endDate
              ? `?startDate=${startDate}&endDate=${endDate}`
              : ''
          }`}
        >
          <a
            className={styles['card__link']}
            target={newTab ? '_blank' : '_self'}
            data-event={'Consumer_App-Campground-Card'}
          >
            <div className={`${imgContainerClasses} ${classNameImgContainer}`}>
              <AppPhoto
                className={imgClasses}
                src={campgroundOrLocation.photoUrl}
                alt={`Camper-submitted photo from ${campgroundOrLocation.name}`}
                locationType={locationType}
                options={{
                  width: WIDTH,
                  height: HEIGHT,
                  fit: 'crop',
                  crop: 'smart',
                }}
                width={`${WIDTH}`}
                height={`${HEIGHT}`}
                lazyLoad={lazyLoad}
              />
              <AppListButton
                className={styles['card__list-button']}
                campground={campgroundOrLocation}
              />
              <AppCampgroundPillDiscount
                campground={campgroundOrLocation}
                className={styles['pro-upsell']}
              />
              <AppCampgroundPillInstant
                campground={campgroundOrLocation}
                className={styles['instant-book']}
              />
              {campgroundOrLocation.bookingMethod !== 'instant' && (
                <AppCampgroundPillAvailability
                  campground={campgroundOrLocation}
                  className={styles['availability']}
                />
              )}
            </div>
            <>
              {!imageCardOnly && (
                <>
                  <h3 className={styles['card__title']}>
                    {campgroundOrLocation.name}
                  </h3>
                  {adminArea && (
                    <p className={styles['card__admin']}>{adminArea.name}</p>
                  )}
                  {!isDumpOrWater && (
                    <div className={styles['card__rating']}>
                      {!campgroundOrLocation.reviewsCount && (
                        <>Be the first to review!</>
                      )}
                      {campgroundOrLocation.reviewsCount > 0 && (
                        <>
                          <AppStarRating
                            rating={campgroundOrLocation.rating || 0}
                            size="sm"
                          />
                          <span className={styles['card__rating-label']}>
                            {campgroundOrLocation.reviewsCount} Reviews
                          </span>
                        </>
                      )}
                    </div>
                  )}
                </>
              )}
            </>
          </a>
        </AppLink>
      </div>
    );
  }
);

AppCampgroundCard.displayName = 'AppCampgroundCard';

export default AppCampgroundCard;
