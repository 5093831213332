import React from 'react';
import classNames from 'classnames/bind';

import { useCampgroundsSavedToLists } from 'contexts/campgroundsSavedToLists';
import { useUser } from 'contexts/currentUser';

import { useOverlayContext } from 'hooks/useOverlayContext';

import AppIcon from 'components/AppIcon/AppIcon';

import styles from './styles/AppListButton.module.scss';

const cx = classNames.bind(styles);

export interface AppListButtonProps {
  className?: string;
  campground: CampgroundOrLocationModel;
}

export const AppListButton: React.FC<AppListButtonProps> = ({
  className,
  campground,
}: AppListButtonProps) => {
  const overlayContext = useOverlayContext<CampgroundSaveToListOverlay>();
  const { campgroundsSavedToLists, setCampgroundToSaveOnLogin } =
    useCampgroundsSavedToLists();
  const { user: currentUser } = useUser();
  const campgroundId =
    'locationId' in campground ? String(campground.locationId) : campground.id;
  const onList = campgroundsSavedToLists.includes(campgroundId);

  const classesSavedIcon = cx({
    'save-button__icon': true,
    'save-button__icon--on-list': onList,
  });

  if (campground.type === 'location-search-results') {
    const location = campground as LocationSearchResultModel;
    if (location.locationType !== 'campground') return null;
  }

  const showOverlay = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (currentUser) {
      overlayContext.setOverlay({
        type: 'save',
        campground,
      });
    } else {
      setCampgroundToSaveOnLogin(campground as CampgroundSearchResultModel);

      overlayContext.setOverlay({
        type: 'sign-in-sign-up',
        redirect: false,
        authFormType: 'sign-up',
      });
    }
  };

  return (
    <div
      className={`${styles['save-button']} ${className}`}
      onTouchEnd={showOverlay}
      onClick={showOverlay}
    >
      <AppIcon
        classNameSvg={classesSavedIcon.split(' ')}
        icon={onList ? 'heart' : 'heart_outlined'}
      />
    </div>
  );
};

export default AppListButton;
