import React from 'react';
import Image from 'next/image';

import useCleanRoute from 'hooks/useCleanRoute';

import AppLink from 'components/AppLink/AppLink';

import styles from './styles/AppCardCarousel.module.scss';

export interface FilterCardProps {
  filter: SeoFilter;
  place: PlaceModel;
  className?: string;
}

export const FilterCard = React.forwardRef<HTMLAnchorElement, FilterCardProps>(
  ({ filter, place, className }: FilterCardProps, ref) => {
    const cleanRoute = useCleanRoute();

    const cardTitle = `${filter.displayName} ${
      place.placeType === 'state' ? `in ${place.name}` : `near  ${place.name}`
    }`;

    const routePrefix = () => {
      let prefix = cleanRoute.split('/with')[0];
      prefix = prefix.replace(/(\/cities)|(\/parks)/g, '');
      return prefix;
    };

    const getButtonFilter = (filter: SeoFilter) => {
      return {
        path: `${routePrefix()}/with/${filter.slug}`,
        active: false,
        dataEvent: `click_disable_filter_${filter.value}`,
      };
    };

    return (
      <AppLink href={getButtonFilter(filter).path}>
        <a
          className={`${styles['card']} ${styles['card--is-filter']} ${className}`}
          ref={ref}
          data-event={'Consumer_Carousel-Filter-Card'}
        >
          <Image
            className={styles['card__image']}
            src={filter.image}
            alt={filter.displayName}
            loading="lazy"
            width={260}
            height={260}
          />
          <div className={styles['card__content']}>
            <h3 className={styles['card__title']}> {cardTitle}</h3>
          </div>
        </a>
      </AppLink>
    );
  }
);

FilterCard.displayName = 'FilterCard';

export default FilterCard;
